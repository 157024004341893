<template>
  <div >
    <Dashboard style="margin:-110px;margin-bottom:0"/>
  </div>
</template>
<script>
import Dashboard from '../landingPage/DashBoard'
export default {
  components:{
    Dashboard
  }
}
</script>
<style>
  
</style>